import React, { useState } from 'react'
import Dropdown from '../../../../components/dropdown/Dropdown'
import { DateRangeSelector } from '@kenect-ut/kenect-ui-kit'

import './DateRangeDropdown.scss'
import DropdownActions from '../DropdownActions'
import { useNavigationContext } from '../../Navigation.context'

function DateRangeDropdown () {
  const { selectedDateRange, setSelectedDateRange } = useNavigationContext()
  const [workingRange, setWorkingRange] = useState()

  const handleSave = () => setSelectedDateRange(workingRange)
  const handleChange = (value) => setWorkingRange(value)

  return (
    <Dropdown title='Date Range' value={selectedDateRange?.label}>
      <DateRangeSelector initialRange={selectedDateRange} onChange={handleChange} className='navDateRangePicker' />
      <DropdownActions save={handleSave} />
    </Dropdown>
  )
}

export default DateRangeDropdown
