class AxiosRequest {
  constructor () {
    this.user = null
    this.accessTokenPromise = null
    this.accessToken = null
  }

  setUserToken = (authUser) => {
    this.user = authUser
    this.accessTokenPromise = authUser.getIdToken ? authUser.getIdToken() : Promise.resolve()
    this.accessTokenPromise.then((newAccessToken) => {
      this.accessToken = newAccessToken
      this.accessTokenPromise = null
    })
  }

  watchOutgoing = (config) => {
    if (this.accessTokenPromise) {
      this.accessTokenPromise.then(this.getConfig(config))
    } else {
      this.getConfig(config)()
    }
    return config
  }

  getConfig = (config) => {
    return (newAccessToken) => {
      if (!config.headers) {
        config.headers = {}
      }
      const token = newAccessToken || this.accessToken
      config.headers.Authorization = 'Bearer ' + token
      return config
    }
  }
}

// eslint-disable-next-line
export default new AxiosRequest()
