import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import classnames from 'classnames'

function NavButton ({ children, active, to }) {
  const linkClassNames = classnames({
    navButton: true,
    active
  })

  return (
    <NavLink end to={to} className={linkClassNames}>{children}</NavLink>
  )
}

NavButton.propTypes = {
  active: PropTypes.bool,
  to: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf([PropTypes.node])
  ])
}

export default NavButton
