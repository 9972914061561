/*
 * calculate next array index based on a direction, keep index in bounds
 * length: size of array we are considering in bounds
 * currentCount: current index number within array
 * currentDirection: current moving in a +/- direction (+1 || -1)
 */
function nextCount (length, currentCount, currentDirection) {
  let direction = currentDirection
  let count = currentCount
  let newValue

  if ((count >= (length - 1) && direction === 1) || (count <= 0 && direction === -1)) {
    newValue = count + (direction * -1)
    direction = direction * -1
  } else {
    newValue = count + direction
  }
  count = newValue

  return {
    direction,
    count
  }
}
export default nextCount
