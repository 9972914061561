import React, {
  useContext,
  createContext,
  useState,
  useMemo,
  useCallback
} from 'react'
import {
  startOfWeek,
  endOfWeek
} from 'date-fns'
import PropTypes from 'prop-types'
import useLocalStorage from '../../hooks/useLocalStorage/useLocalStorage'
import { sortBy } from 'lodash'

export const NavigationContext = createContext({})
export const NavigationContextProvider = ({ children }) => {
  const [userBrands, setUserBrands] = useState([])
  const [selectedBrands, setSelectedBrands] = useLocalStorage('SelectedBrands', [])
  const [selectedOrg, setSelectedOrg] = useLocalStorage('SelectedOrg', {})
  const [selectedDateRange, setSelectedDateRange] = useState({
    label: 'This Week',
    range: {
      startDate: startOfWeek(new Date()),
      endDate: endOfWeek(new Date())
    }
  })

  const handleSetUserBrands = useCallback((brands) => {
    const isArray = Array.isArray(brands)
    setUserBrands(isArray ? sortBy(brands, ['name']) : [])
  }, [])

  const startDate = selectedDateRange?.range?.startDate?.getTime()
  const endDate = selectedDateRange?.range?.endDate?.getTime()
  const selectedBrandIds = useMemo(() => selectedBrands?.map(brand => brand?.id), [selectedBrands])
  const selectedOrgId = selectedOrg?.organization?.id

  if (!startDate || !endDate || startDate > endDate) {
    throw new Error('Date range is invalid')
  }

  const context = {
    setSelectedBrands,
    setSelectedDateRange,
    setSelectedOrg,
    selectedOrg,
    selectedOrgId,
    selectedBrands,
    selectedDateRange,
    userBrands,
    setUserBrands: handleSetUserBrands,
    startDate,
    endDate,
    selectedBrandIds
  }

  return (
    <NavigationContext.Provider value={context}>{children}</NavigationContext.Provider>
  )
}

export const useNavigationContext = () => useContext(NavigationContext)

NavigationContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf([PropTypes.node])
  ]).isRequired
}
