import React from 'react'
import { Button, Icon } from '@kenect-ut/kenect-ui-kit'
import Fire from '../firebaseConfig'

import './Navigation.scss'
import NavButtons from '../../components/navigation/NavButtons'
import NavButton from '../../components/navigation/NavButton'
import BrandDropdown from './dropDowns/brand/BrandDropdown'
import DateRangeDropdown from './dropDowns/dateRange/DateRangeDropdown'
import OrgDropdown from './dropDowns/organization/OrgDropdown'
import Permissions from '../auth/permissions/Permissions'
import { PERM_OEM_ADMIN, PERM_OEM_SYSADMIN } from '../auth/permissions/Permissions.constants'
import { useNavigate } from 'react-router-dom'

function Navigation () {
  const navigate = useNavigate()

  const handleSignOut = () => {
    Fire.Auth.signOut().finally(() => {
      navigate('/')
    })
  }

  return (
    <div className='navBar'>
      <div className='navBarTop'>
        <img
          className='navLogo'
          src='/images/logo.svg'
          alt='Kenect OEM Login'
        />
        <div className='navTopAction'>
          <Button onClick={handleSignOut} variant='tertiary'><><Icon name='sign-out' />Sign out</></Button>
        </div>
      </div>
      <div className='navBarBottom'>
        <NavButtons>
          <NavButton to='/'>Dashboard</NavButton>
          <NavButton to='/dealer-table'>Dealer Table</NavButton>
          <Permissions requiredPermissions={[PERM_OEM_SYSADMIN]}>
            <NavButton to='/response-rates'>Response Rates</NavButton>
          </Permissions>
        </NavButtons>
        <div className='navFilters'>
          <Permissions requiredPermissions={[PERM_OEM_ADMIN]}>
            <OrgDropdown />
          </Permissions>
          <BrandDropdown />
          <DateRangeDropdown />
        </div>
      </div>
    </div>
  )
}

export default Navigation
