import React, { StrictMode } from 'react'
import { render } from 'react-dom'
import reportWebVitals from './app/reportWebVitals'
import axios from 'axios'

import Fire from './app/firebaseConfig'

import './styles/reset.scss'
import '@kenect-ut/kenect-ui-kit/lib/styles.css'
import './styles/app.scss'
import { localConfig, networkConfig } from './app/config'
import App from './app/App'
import AppError from './app/errors/AppError'

const renderApp = () => render(
  <App />,
  document.getElementById('root')
)

const renderError = () => render(
  <StrictMode>
    <AppError />
  </StrictMode>,
  document.getElementById('root')
)

const startAppWithConfig = (response) => {
  try {
    networkConfig.setConfig(response.data)
    networkConfig.check()
    Fire.initialize()
    renderApp()
  } catch (err) {
    console.error(err)
    renderError()
  }
}

const getLocalConfig = () => Promise.resolve({ data: localConfig })
const getRemoteConfig = () => axios.get('/config')

let getConfigPromise = null

if (window.location.host.includes('localhost')) {
  getConfigPromise = getLocalConfig()
} else {
  getConfigPromise = getRemoteConfig()
}

getConfigPromise.then(startAppWithConfig)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
