import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, useLocation } from 'react-router-dom'
import useUserPermissions from '../permissions/Permissions.hook'

function ProtectedRoute ({ requiredPermissions, children }) {
  const { hasAccess } = useUserPermissions({ requiredPermissions })
  const location = useLocation()

  if (!hasAccess) {
    return (
      <Navigate
        to={{
          pathname: '/',
          state: { from: location }
        }}
        replace
      />
    )
  }

  return children
}

ProtectedRoute.propTypes = {
  requiredPermissions: PropTypes.array
}

export default ProtectedRoute
