import React, {
  useState,
  useEffect
} from 'react'
import nextCount from './utils/nextCount'
import './Loading.scss'

const DOTS = [0, 1, 2, 3, 4, 5, 6, 7, 8]
const DELAY_MS = 100

const Loading = (props) => {
  const [count, setCount] = useState(0)
  const [dir, setDir] = useState(1)

  useEffect(() => {
    const timer = window.setTimeout(() => {
      const next = nextCount(DOTS.length, count, dir)
      if (next.direction !== dir) {
        setDir(next.direction)
      }
      setCount(next.count)
    }, DELAY_MS)
    return () => {
      clearTimeout(timer)
    }
  }, [count, dir])

  const renderDots = () => {
    return DOTS.map((_, idx) => {
      let cn = 'Loading__Dot'
      if (count === idx) {
        cn += ' Loading__Dot__Active'
      }
      return <div className={cn} key={idx} />
    })
  }

  return (
    <div className='Loading'>
      {renderDots()}
    </div>
  )
}
export default Loading
