import React, {
  useContext,
  useState,
  createContext,
  useEffect
} from 'react'
import PropTypes from 'prop-types'
import Fire from '../../firebaseConfig'
import AxiosRequest from '../../../async/axios/AxiosRequest'
import { checkUserAccess } from '../permissions/Permissions.utils'
import { PERM_OEM_DEMO_USER, PERM_OEM_USER } from '../permissions/Permissions.constants'
import uniq from 'lodash/uniq'

export const UserContext = createContext({})
export const UserContextProvider = ({ children }) => {
  const [loadingUser, setLoadingUser] = useState(true)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [userHasOemAccess, setUserHasOemAccess] = useState(true)

  const [authUser, setAuthUser] = useState({})
  const [user, setUser] = useState({})
  const [permissions, setPermissions] = useState({})

  const uid = authUser?.uid

  useEffect(() => {
    const unsub = Fire.Auth.onIdTokenChanged(incomingAuthUser => {
      if (incomingAuthUser) {
        setAuthUser(incomingAuthUser)
        AxiosRequest.setUserToken(incomingAuthUser)
      } else {
        setLoadingUser(false)
        setAuthUser({})
        setIsLoggedIn(false)
        AxiosRequest.setUserToken({})
      }
    })

    return unsub
  }, [])

  useEffect(() => {
    let unsub

    if (uid) {
      setLoadingUser(true)

      unsub = Fire.DB
        .collection('userAuthorization')
        .doc(uid)
        .onSnapshot(doc => {
          const incomingUser = doc.data()
          setUser(incomingUser)
          const userRoles = incomingUser?.roles || {}
          const userOemRoles = incomingUser?.oemRoles || {}
          const allUserRoles = { ...userOemRoles, ...userRoles }
          const uniqueRoles = uniq(Object.values(allUserRoles))

          const getRolePromises = []
          uniqueRoles.forEach(role => {
            getRolePromises.push(Fire.DB.collection('roles').doc(role).get())
          })

          Promise.all(getRolePromises)
            .then(res => {
              const permissionsByRole = {}
              res.forEach(doc => {
                const data = doc.data()
                permissionsByRole[data.role] = data.permissions
              })

              const userBrandIds = Object.keys(allUserRoles)
              const userPermissionsByBrandIdOrLocationId = {}

              userBrandIds.forEach(brandId => {
                userPermissionsByBrandIdOrLocationId[brandId] = permissionsByRole[allUserRoles[brandId]]
              })

              const userHasOemAccess = checkUserAccess(userPermissionsByBrandIdOrLocationId, [PERM_OEM_USER, PERM_OEM_DEMO_USER])

              setPermissions(userPermissionsByBrandIdOrLocationId)
              setUserHasOemAccess(userHasOemAccess)
              setIsLoggedIn(true)
              setLoadingUser(false)
            })
        })
    }
    return unsub
  }, [uid])

  const context = {
    isLoggedIn,
    loadingUser,
    authUser,
    user,
    permissions,
    userHasOemAccess
  }

  return (
    <UserContext.Provider value={context}>{children}</UserContext.Provider>
  )
}

export const useUserContext = () => useContext(UserContext)

UserContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf([PropTypes.node])
  ]).isRequired
}
