import React, {
  useContext,
  createContext,
  useCallback
} from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { useToastAlert } from '@kenect-ut/kenect-ui-kit'

export const ToastAlertContext = createContext({})
export const ToastAlertContextProvider = ({ children }) => {
  const [toastAlert, showAlert] = useToastAlert('#root')

  const showAxiosAlert = useCallback((message, options = {}) => {
    const { error, toastOptions } = options
    const colors = ['blue', 'green', 'orange', 'red']
    const nextOptions = {
      color: 'red', // default, but should be override-able,
      isDarkMode: false,
      ...toastOptions
    }
    const colorError = `showAxiosAlert()'s toastOptions color property must be a string of one of the following: '${colors.join("', '")}'.`

    if (error && axios.isCancel(error)) {
      return
    } else if (toastOptions?.color && !colors.includes(toastOptions.color)) {
      return console.warn(colorError)
    }

    showAlert(message, nextOptions)
  }, [showAlert])

  const context = {
    showAxiosAlert,
    showAlert
  }

  return (
    <>
      <ToastAlertContext.Provider value={context}>{children}</ToastAlertContext.Provider>
      {toastAlert}
    </>
  )
}

export const useToastAlertContext = () => useContext(ToastAlertContext)

ToastAlertContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf([PropTypes.node])
  ]).isRequired
}
