import loadable from '@loadable/component'
import Loading from '../components/loading/Loading'

export const LoadingComponent = Loading

export const LoadableDashboard = loadable(() => import('./dashboard/Dashboard'), LoadingComponent)
export const LoadableDealerTable = loadable(() => import('./dealerTable'), LoadingComponent)
export const LoadableResponseRatesPage = loadable(() => import('./responseRates/ResponseRatesPage'), LoadingComponent)

// Auth Pages
export const LoadableLogin = loadable(() => import('./login/Login'), LoadingComponent)
export const LoadableForgotPassword = loadable(() => import('./forgotPassword/ForgotPasswordPage'), LoadingComponent)
