import React from 'react'
import AppRoutes from '../routes'
import { UserContextProvider } from './auth/userContext/UserContext'
import { NavigationContextProvider } from './navigation/Navigation.context'
import { ToastAlertContextProvider } from './toastAlert/ToastAlertContext'

function App () {
  return (
    <ToastAlertContextProvider>
      <UserContextProvider>
        <NavigationContextProvider>
          <AppRoutes />
        </NavigationContextProvider>
      </UserContextProvider>
    </ToastAlertContextProvider>
  )
}

export default App
