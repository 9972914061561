import { intersectionWith } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { getBrandsByOemId, getBrandsByUser } from '../../../../async/v1/oem-dashboard/brands'
import Dropdown from '../../../../components/dropdown/Dropdown'
import { PERM_OEM_ADMIN, PERM_OEM_DEMO_USER } from '../../../auth/permissions/Permissions.constants'
import useUserPermissions from '../../../auth/permissions/Permissions.hook'
import { getDemoBrands } from '../../../demo/mockAsync'
import { useToastAlertContext } from '../../../toastAlert/ToastAlertContext'
import { useNavigationContext } from '../../Navigation.context'
import './BrandDropdown.scss'
import BrandDropdownForm from './BrandDropdownForm'

function BrandDropdown () {
  const [loading, setLoading] = useState(true)
  const { selectedBrands, setSelectedBrands, userBrands, setUserBrands, selectedOrg } = useNavigationContext()
  const { showAxiosAlert } = useToastAlertContext()

  const { hasAccess: isAdmin } = useUserPermissions({ requiredPermissions: [PERM_OEM_ADMIN] })
  const { hasAccess: isDemoUser } = useUserPermissions({ requiredPermissions: [PERM_OEM_DEMO_USER] })

  useEffect(() => {
    if (isDemoUser) {
      setUserBrands(getDemoBrands())
      setLoading(false)
    } else {
      let getBrandsPromise
      if (isAdmin) {
        const orgId = selectedOrg?.organization?.id
        if (orgId) {
          getBrandsPromise = getBrandsByOemId(orgId)
        }
      } else {
        getBrandsPromise = getBrandsByUser()
      }

      getBrandsPromise?.then && getBrandsPromise
        .then(res => {
          const incomingBrands = res?.data || []
          const filteredBrands = incomingBrands.filter(brand => !brand.inactiveDate)
          setUserBrands(filteredBrands)
          setSelectedBrands(cur => !cur?.length ? filteredBrands : cur)
        })
        .catch(error => {
          showAxiosAlert('Unable to retrieve brands.', { error })
        })
        .finally(() => {
          setLoading(false)
        })
    }
    // Disabling because we don't want to re-render when the value of selectedBrands changes the reference to the setSelectedBrands callback
    // eslint-disable-next-line
  }, [setUserBrands, showAxiosAlert, isAdmin, selectedOrg?.organization?.id])

  const handleInitialValues = () => ({
    brandIds: selectedBrands.map(brand => brand.id)
  })

  const handleApply = (formValues) => {
    const { brandIds } = formValues
    const filteredBrands = intersectionWith(userBrands, brandIds, (brand, brandId) => brand?.id === brandId)
    setSelectedBrands(filteredBrands)
  }

  const handleDropdownLabel = () => {
    const selectedLength = selectedBrands?.length
    const userBrandsLength = userBrands?.length

    if (isAdmin) {
      const orgId = selectedOrg?.organization?.id
      if (!orgId) return 'Please select an organization'
      else if (!selectedLength) return 'Please select a brand'
    }

    if (selectedLength === 1) return selectedBrands[0]?.name
    else if (selectedLength === userBrandsLength) return 'All Brands'
    else return 'Multiple Brands'
  }

  return (
    <Form
      onSubmit={handleApply}
      initialValues={handleInitialValues()}
      mutators={{
        clearAllBrands: (args, state, utils) => {
          utils.changeValue(state, 'brandIds', () => [])
        },
        setAllBrands: (args, state, utils) => {
          utils.changeValue(state, 'brandIds', () => userBrands.map(brand => brand.id))
        }
      }}
      render={(props) => {
        const { handleSubmit, values, form } = props
        return (
          <Dropdown title='Brands' value={handleDropdownLabel()} className='mr-16'>
            <BrandDropdownForm
              userBrands={userBrands}
              loading={loading}
              formValues={values}
              form={form}
              onValueChange={handleSubmit}
            />
          </Dropdown>
        )
      }}
    />
  )
}

export default BrandDropdown
