export const localConfig = {
  env: process.env.REACT_APP_ENV,

  timeout: process.env.REACT_APP_TIMEOUT,

  LEAD_MANAGEMENT_BASE: process.env.REACT_APP_LEAD_MANAGEMENT_BASE,
  OEM_DASHBOARD_BASE: process.env.REACT_APP_OEM_DASHBOARD_BASE,
  LOCATION_SERVICE_BASE: process.env.REACT_APP_LOCATION_SERVICE_URL,

  FIREBASE_API_KEY: process.env.REACT_APP_KENECT_UI_FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN: process.env.REACT_APP_KENECT_UI_FIREBASE_AUTH_DOMAIN,
  FIREBASE_DATABASE_URL: process.env.REACT_APP_KENECT_UI_FIREBASE_DATABASE_URL,
  FIREBASE_PROJECT_ID: process.env.REACT_APP_KENECT_UI_FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET: process.env.REACT_APP_KENECT_UI_FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGE_SENDER_ID: process.env.REACT_APP_KENECT_UI_FIREBASE_MESSAGE_SENDER_ID,
  FIREBASE_APP_ID: process.env.REACT_APP_KENECT_UI_FIREBASE_APP_ID,
  FIREBASE_MEASUREMENT_ID: process.env.REACT_APP_KENECT_UI_FIREBASE_MEASUREMENT_ID
}

class NetworkConfig {
  constructor () {
    this.config = {}
  }

  setConfig = config => {
    this.config = config
  }

  getConfig = () => {
    return this.config
  }

  check = () => {
    if (!this.config.env) {
      throw new Error('No environment set.')
    }
  }
}

export const networkConfig = new NetworkConfig()
export default networkConfig.getConfig
