import config from '../../../app/config'
import AxiosInstance from '../../axios/AxiosInstance'

export const getBrandsByUser = () => {
  return AxiosInstance.get(`${config().OEM_DASHBOARD_BASE}brands/by-user`)
}

export const getBrandsByOemId = (OemId) => {
  return AxiosInstance.get(`${config().OEM_DASHBOARD_BASE}brands/by-oem`, { params: { oemOrganizationId: OemId } })
}
