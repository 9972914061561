import { Icon } from '@kenect-ut/kenect-ui-kit'
import Popover from '@material-ui/core/Popover'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { Children, cloneElement, isValidElement, useState } from 'react'
import './Dropdown.scss'

function Dropdown ({
  title,
  value,
  className,
  children,
  onClose
}) {
  const [anchorEl, setAnchorEl] = useState(null)
  const iconClassName = classNames('dropdownCarat', !!anchorEl && 'rotateCarat')
  const wrapperClassName = classNames(className, 'dropdownWrapper')

  const handleClose = (e) => {
    setAnchorEl(null)
    onClose && onClose()
  }
  const handleOpen = (e) => setAnchorEl(e.currentTarget)

  const childrenWithProps = Children.map(children, child => {
    if (isValidElement(child)) {
      return cloneElement(child, { close: handleClose })
    }
    return child
  })

  return (
    <>
      <button onClick={handleOpen} className={wrapperClassName}>
        <div>
          <div className='dropdownTitle'>{title}</div>
          <div className='dropdownValue'>{value}</div>
        </div>
        <div className='iconContainer'><Icon className={iconClassName} name='carat-solid' /></div>
      </button>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <div onMouseLeave={handleClose}>
          {childrenWithProps}
        </div>
      </Popover>
    </>
  )
}

Dropdown.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string
  ]),
  value: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string
  ]),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf([PropTypes.node])
  ]).isRequired,
  className: PropTypes.string,
  onClose: PropTypes.func
}

export default Dropdown
