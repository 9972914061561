import React from 'react'
import PropTypes from 'prop-types'
import useUserPermissions from './Permissions.hook'

function Permissions ({ children, requiredPermissions, noAccess }) {
  const { hasAccess } = useUserPermissions({ requiredPermissions })
  const forRenderNoAccess = noAccess || null
  return (
    <>{hasAccess ? children : forRenderNoAccess}</>
  )
}

Permissions.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf([PropTypes.node])
  ]).isRequired,
  requiredPermissions: PropTypes.array.isRequired,
  noAccess: PropTypes.node
}

export default Permissions
