import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useUserContext } from '../userContext/UserContext'
import { checkUserAccess } from './Permissions.utils'

function useUserPermissions (props) {
  const { requiredPermissions = [], brandId } = props || {}
  const { permissions } = useUserContext()

  const hasAccess = useMemo(() => checkUserAccess(permissions, requiredPermissions, brandId), [permissions, requiredPermissions, brandId])
  return { hasAccess }
}

useUserPermissions.propTypes = {
  requiredPermissions: PropTypes.array,
  brandId: PropTypes.number
}

export default useUserPermissions
