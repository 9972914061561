import { Icon, ModalAlert } from '@kenect-ut/kenect-ui-kit'
import React, { useEffect, useState } from 'react'
import { useUserContext } from '../../app/auth/userContext/UserContext'
import Fire from '../../app/firebaseConfig'
import './Unauthorized.scss'

function Unauthorized () {
  const [open, setOpen] = useState(false)
  const { userHasOemAccess, user } = useUserContext()
  const { userName } = user || {}

  const handleSignOut = () => Fire.Auth.signOut()

  useEffect(() => {
    setOpen(true)
  }, [])

  return (
    <div className='unauth__page' id='unauth__page'>
      <ModalAlert
        id='alert'
        parentSelector='#unauth__page'
        isOpen={!userHasOemAccess || open}
        color='red'
        icon={<Icon name='alert-triangle' className='modal__icon' />}
        title='Permission Required'
        message={`The authenticated account ${userName ? `(${userName})` : ''} does not have permission to view the content of this application.`}
        primaryButtonLabel='Sign Out'
        onPrimaryButtonClicked={handleSignOut}
      />
    </div>
  )
}

export default Unauthorized
