import { generateDealerLeads, generateDealerTablePayload, generateLeaderboardData, generateOEMLeads } from './mock.utils'

export const DemoLeadsByDealer = () => {
  return generateDealerLeads()
}

export const DemoOEMLeads = () => {
  return generateOEMLeads()
}

export const DemoLeaderboardStats = () => {
  return generateLeaderboardData()
}

export const DemoDealerTableLeads = (displayAmount = 100) => {
  return generateDealerTablePayload(displayAmount)
}

export const getDemoBrands = (displayAmount = 100) => {
  return [
    {
      id: 11,
      organizationId: 774,
      name: 'Kenect Water Sports',
      inactiveDate: 0
    },
    {
      id: 12,
      organizationId: 774,
      name: 'Kenect Power Sports',
      inactiveDate: 0
    },
    {
      id: 13,
      organizationId: 774,
      name: 'Kenect RV Emporium',
      inactiveDate: 0
    }
  ]
}
