import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@kenect-ut/kenect-ui-kit'

function AppError ({ error, buttonLabel, onButtonClick }) {
  const handleAction = () => {
    onButtonClick && onButtonClick()
  }
  return (
    <div className='appErrorPage'>
      <div className='appErrorModal'>
        {error || 'An error occurred. Please try again later.'}
        {buttonLabel && <Button onClick={handleAction}>{buttonLabel}</Button>}
      </div>
    </div>
  )
}

AppError.propsTypes = {
  error: PropTypes.string,
  buttonLabel: PropTypes.string,
  onButtonClick: PropTypes.func
}

export default AppError
