import React, { Children, cloneElement } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

function NavButtons ({ children }) {
  const { pathname } = useLocation()
  const childrenWithProps = Children.map(children, (child, index) => {
    const isActive = child.props.to === pathname
    return cloneElement(child, { key: `tab_${index}`, active: isActive })
  })

  return (
    <div className='navButtons'>
      {childrenWithProps}
    </div>
  )
}

NavButtons.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf([PropTypes.node])
  ])
}

export default NavButtons
