import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Icon } from '@kenect-ut/kenect-ui-kit'
import { Field } from 'react-final-form'

function OrgDropdownForm ({
  organizations,
  loading,
  formValues,
  onValueChange
}) {
  const [search, setSearch] = useState('')
  const handleSearch = (e) => setSearch(e.target.value)

  const filteredOrgs = useMemo(() => (search
    ? organizations.filter(org => org?.organization?.name.toLowerCase().includes(search.toLowerCase()))
    : organizations
  ), [search, organizations])

  return (
    <div className='orgSelectOptions'>
      <input value={search} onChange={handleSearch} placeholder='Search' />
      <div className='break' />
      {
        loading && 'Loading...'
      }
      {
        filteredOrgs.map(organization => {
          const org = organization.organization || {}
          const id = `org_${org.id}`
          const isChecked = formValues?.orgId?.includes(org.id)

          const iconClassName = classNames('orgSelectIcon', isChecked && 'isVisible')
          return (
            <label key={id} htmlFor={id} className='orgSelectOption'>
              <Icon
                name='check'
                className={iconClassName}
              />
              <span>{org.name}</span>
              <Field
                name='orgId'
                type='radio'
                value={org.id}
                component={({ input }) => {
                  const handleChange = (event) => {
                    input.onChange(event.target.value)
                    onValueChange()
                  }
                  return <input {...input} id={id} className='hide' onChange={handleChange} />
                }}
              />
            </label>
          )
        })
      }
    </div>
  )
}

OrgDropdownForm.propTypes = {
  organizations: PropTypes.array,
  loading: PropTypes.bool,
  formValues: PropTypes.object,
  onValueChange: PropTypes.func.isRequired
}

export default OrgDropdownForm
