import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Icon } from '@kenect-ut/kenect-ui-kit'
import Spinner from '../../../../components/spinner/Spinner'
import { union } from 'lodash'
import { Field } from 'react-final-form'

function BrandDropdownForm ({
  userBrands,
  loading,
  formValues,
  form,
  onValueChange
}) {
  const brandIds = formValues?.brandIds || []
  const allBrandsIsChecked = userBrands.length === brandIds?.length
  const allBrandsIconClassName = classNames('brandSelectIcon', allBrandsIsChecked && 'isVisible')

  const handleAllBrandsClick = () => {
    form.mutators.setAllBrands()
    onValueChange()
  }

  return (
    <form className='brandSelectWrapper'>
      <div className='brandSelectOptions'>
        <label htmlFor='brandOptionAllBrands' className='brandSelectOption' onClick={handleAllBrandsClick}>
          <Icon
            name='check'
            className={allBrandsIconClassName}
          />
          <span>All Brands</span>
        </label>

        <div className='break' />
        {
          userBrands.map(brand => {
            const id = `brand_${brand.id}`
            const isChecked = brandIds.includes(brand.id)
            const iconClassName = classNames('brandSelectIcon', isChecked && !allBrandsIsChecked && 'isVisible')
            return (
              <label key={id} htmlFor={id} className='brandSelectOption'>
                <Icon
                  name='check'
                  className={iconClassName}
                />
                <span>{brand.name}</span>
                <Field
                  name='brandIds'
                  type='checkbox'
                  value={brand.id}
                  component={({ input }) => {
                    const handleChange = (event) => {
                      const preemptiveValue = union(brandIds, [brand.id])
                      if (preemptiveValue.length === 1) {
                        // If the preemptiveValue length is one, we know they are trying to remove the last selected brand. This is not aloud.
                        return
                      } else if (allBrandsIsChecked) {
                        form.mutators.clearAllBrands()
                        input.onChange([brand.id])
                      } else {
                        input.onChange(event)
                      }

                      onValueChange()
                    }
                    return <input id={id} className='hide' {...input} onChange={handleChange} />
                  }}
                />
              </label>
            )
          })
        }
      </div>

      {
        loading && <Spinner />
      }
    </form>
  )
}

BrandDropdownForm.propTypes = {
  userBrands: PropTypes.array,
  loading: PropTypes.bool,
  formValues: PropTypes.shape({
    brandIds: PropTypes.arrayOf(PropTypes.number),
    allBrands: PropTypes.bool
  }),
  form: PropTypes.object,
  onValueChange: PropTypes.func.isRequired
}

export default BrandDropdownForm
