import React from 'react'
import Navigation from '../app/navigation/Navigation'
import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom'
import * as LoadableRoutes from './loadableRoutes'
import { useUserContext } from '../app/auth/userContext/UserContext'
import Unauthorized from './unauthorizedLogin/Unauthorized'
import ProtectedRoute from '../app/auth/protectedRoute/ProtectedRoute'
import { PERM_OEM_SYSADMIN } from '../app/auth/permissions/Permissions.constants'

function AppRoutes () {
  const { isLoggedIn, loadingUser, userHasOemAccess } = useUserContext()

  if (loadingUser) {
    return <LoadableRoutes.LoadingComponent />
  } else if (isLoggedIn && userHasOemAccess) {
    return (
      <Router>
        <Navigation />
        <Routes>
          <Route
            path='/response-rates'
            element={
              <ProtectedRoute
                requiredPermissions={[PERM_OEM_SYSADMIN]}
                path='/response-rates'
              >
                <LoadableRoutes.LoadableResponseRatesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path='/dealer-table'
            element={<LoadableRoutes.LoadableDealerTable />}
          />
          <Route path='/' element={<LoadableRoutes.LoadableDashboard />} />
        </Routes>
      </Router>
    )
  } else if (isLoggedIn && !userHasOemAccess) {
    return (
      <Unauthorized />
    )
  } else {
    return (
      <Router>
        <Routes>
          <Route
            path='/forgot-password'
            element={<LoadableRoutes.LoadableForgotPassword />}
          />
          <Route path='/' element={<LoadableRoutes.LoadableLogin />} />
        </Routes>
      </Router>
    )
  }
}

AppRoutes.propTypes = {

}

export default AppRoutes
