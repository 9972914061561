import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'

import './Spinner.scss'

function Spinner ({
  size
}) {
  const circleSize = size || 40
  return (
    <CircularProgress className='spinner' style={{ height: circleSize, width: circleSize }} />
  )
}

Spinner.propTypes = {
  size: PropTypes.number
}

export default Spinner
