import { faker } from '@faker-js/faker'

export const generateDealerLeads = () => {
  const obj = {
    reviewsResult: {
      facebookRating: {

      }
    }
  }
  obj.totalLeads = faker.number.int({ max: 19999 })
  obj.totalOnBrandLeads = faker.number.int({ max: obj.totalLeads / 10 })
  obj.totalResponded = faker.number.int({ max: obj.totalOnBrandLeads / 2 })
  obj.avgResponseTimeInMilli = faker.number.int() * 1000

  obj.reviewsResult.totalReviews = faker.number.int({ max: obj.totalOnBrandLeads / 2 })
  obj.reviewsResult.avgReviewsPerLocation = faker.number.float({
    max: 99.0,
    min: 0.1,
    precision: 0.1
  })
  obj.reviewsResult.googleRating = faker.number.float({
    max: 5.0,
    min: 3.5,
    precision: 0.1
  })

  obj.reviewsResult.facebookRating.positive = faker.number.int({ max: 999 })
  obj.reviewsResult.facebookRating.negatives = faker.number.int({ max: 999 })

  return obj
}

export const generateOEMLeads = () => {
  const obj = {
    leads: []
  }

  obj.totalLeads = faker.number.int({ max: 19999 })
  obj.avgLeadsByLocation = faker.number.int({ max: obj.totalLeads / 10 })
  obj.avgResponseTimeInMilli = faker.number.int() * 1000
  obj.responseRate = faker.number.float({
    max: 100,
    min: 0.0,
    precision: 1.1
  })

  obj.dealsClosed = faker.number.int({ max: 999 })
  obj.dealsCloseRatio = faker.number.float({
    max: 100,
    min: 0.0,
    precision: 0.1
  })

  for (let i = 0; i < 11; i++) {
    obj.leads.push(generateMockLead())
  }

  return obj
}

const generateMockLead = () => {
  return {
    page: `/en-us/${faker.company.name().toLowerCase()}`,
    count: faker.number.int({ max: 999 }),
    percentage: faker.number.float({
      max: 10,
      min: 0,
      precision: 1.1
    }),
    locationId: faker.number.int({ max: 9999 }),
    locationName: faker.company.name()
  }
}

export const generateLeaderboardData = () => {
  const payload = []

  for (let i = 0; i < 11; i++) {
    payload.push(generateMockLead())
  }

  return payload
}

const generateDealerTableRow = () => {
  const demoDealerTableRow = {
    locationId: faker.number.int(),
    locationName: faker.company.name(),
    locationState: faker.location.state(),
    totalDealerLeads: faker.number.int(),
    avgResponseDealerLeads: faker.number.int() * 1000,
    dealerOnBrandLeads: faker.number.int(),
    totalOemLeads: faker.number.int(),
    totalOemResponses: faker.number.int(),
    avgResponseOemLeads: faker.number.int() * 1000,
    dealsClosed: faker.number.int({ max: 999 }),
    totalReviews: faker.number.int({ max: 1999 }),
    googleRating: faker.number.float({
      max: 5.0,
      min: 3.5
    }),
    facebookRating: {
      positives: faker.number.int({ max: 999 }),
      negatives: faker.number.int({ max: 599 })
    }
  }
  return demoDealerTableRow
}

export const generateDealerTablePayload = (generateAmount = 100) => {
  const payload = []
  for (let rows = 0; rows < generateAmount; rows++) {
    payload.push(generateDealerTableRow())
  }
  return payload
}
