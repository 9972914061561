import intersection from 'lodash/intersection'

/**
 * Check if the user has access with permissions. Permissions are stored by brandId.
 *
 * @param {object} permissions The user permissions specified by the UserContext
 * @param {array} requiredPermissions An array of required permissions. You must have at least one to have access
 * @param {number} brandId (Optional) the Id of the brand you would like to check permissions on
 * @returns if the user has access (true/false)
 */
export const checkUserAccess = (permissions, requiredPermissions, brandId) => {
  if (!Array.isArray(requiredPermissions)) return false

  let allPermissions = []
  Object.values(permissions).forEach(brandPermissionArray => {
    allPermissions = [...allPermissions, ...brandPermissionArray]
  })

  const userAccessArray = brandId
    ? intersection(permissions[brandId], requiredPermissions)
    : intersection(allPermissions, requiredPermissions)

  return !!userAccessArray?.length
}
