import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import 'firebase/compat/messaging'
import config from './config'

class Fire {
  initialize () {
    const firebaseConfig = {
      apiKey: config().FIREBASE_API_KEY,
      authDomain: config().FIREBASE_AUTH_DOMAIN,
      databaseURL: config().FIREBASE_DATABASE_URL,
      projectId: config().FIREBASE_PROJECT_ID,
      storageBucket: config().FIREBASE_STORAGE_BUCKET,
      messagingSenderId: config().FIREBASE_MESSAGE_SENDER_ID,
      appId: config().FIREBASE_APP_ID,
      measurementId: config().FIREBASE_MEASUREMENT_ID
    }

    firebase.initializeApp(firebaseConfig)

    this.DB = firebase.firestore()
    this.Auth = firebase.auth()
  }
}

// eslint-disable-next-line
export default new Fire()
