import React, { useEffect, useState } from 'react'
import Dropdown from '../../../../components/dropdown/Dropdown'
import { Form } from 'react-final-form'
import { getOrgsWithFeatures } from '../../../../async/v1/location/organization'
import { FEAT_ORIGINAL_EQUIPMENT_MANUFACTURER } from '../../../../constants/features'
import { useToastAlertContext } from '../../../toastAlert/ToastAlertContext'
import { useNavigationContext } from '../../Navigation.context'

import './OrgDropdown.scss'
import { isEmpty } from 'lodash'
import OrgDropdownForm from './OrgDropdownForm'

function OrgDropdown () {
  const [loading, setLoading] = useState(true)
  const [organizations, setOrganizations] = useState([])

  const { showAxiosAlert } = useToastAlertContext()
  const { setSelectedOrg, selectedOrg, setSelectedBrands } = useNavigationContext()

  useEffect(() => {
    setLoading(true)
    getOrgsWithFeatures([FEAT_ORIGINAL_EQUIPMENT_MANUFACTURER])
      .then(res => setOrganizations(res.data))
      .catch(error => showAxiosAlert('Unable to retrieve organizations', { error }))
      .finally(() => setLoading(false))
  }, [showAxiosAlert])

  const handleSubmit = (formValues) => {
    const { orgId } = formValues
    const parsedOrgId = parseInt(orgId)
    const previousSelectedOrgId = selectedOrg?.organization?.id
    if (parsedOrgId !== previousSelectedOrgId) {
      const formSelectedOrg = organizations.find(org => org?.organization?.id === parsedOrgId)
      setSelectedOrg(formSelectedOrg)
      setSelectedBrands([])
    }
  }

  const handleDropdownLabel = () => {
    return !isEmpty(selectedOrg) ? selectedOrg?.organization?.name : 'Please select an organization'
  }

  const handleInitialValues = () => ({
    orgId: [selectedOrg?.organization?.id]
  })

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={handleInitialValues}
      render={(props) => {
        const { handleSubmit, values } = props
        return (
          <Dropdown title='OEM Organizations' value={handleDropdownLabel()} className='mr-16'>
            <OrgDropdownForm
              organizations={organizations}
              loading={loading}
              formValues={values}
              onValueChange={handleSubmit}
            />
          </Dropdown>
        )
      }}
    />
  )
}

export default OrgDropdown
