import axios from 'axios'
import config from '../../app/config'
import AxiosRequest from './AxiosRequest'
import qs from 'qs'

const instance = axios.create({
  timeout: config().timeout,
  paramsSerializer: params => {
    return qs.stringify(params, { arrayFormat: 'comma' })
  }
})

/**
 * Enables PR environment ([ticket-number].oem.kenect[-env].com) to include
 * their ticket number in the headers so that their API traffic can be routed
 * to the correct instance of the service.
 */
instance.interceptors.request.use(
  (config) => {
    const subdomain = window.location.hostname.split('.')[0].toLowerCase()

    if (!['localhost', 'oem'].includes(subdomain)) {
      config.headers['X-Kenect-Virtual-Environment'] = subdomain
    }
    return config
  }
)

instance.interceptors.request.use(AxiosRequest.watchOutgoing)

export default instance
