import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@kenect-ut/kenect-ui-kit'

function DropdownActions ({
  close,
  save,
  disabled
}) {
  const handleSave = (event) => {
    event.preventDefault()
    close()
    save()
  }
  const handleClose = (event) => {
    event.preventDefault()
    close()
  }
  return (
    <div className='navDateRangePickerActionBar'>
      <Button className='mr-16' variant='tertiary' onClick={handleClose}>Cancel</Button>
      <Button onClick={handleSave} disabled={disabled}>Apply</Button>
    </div>
  )
}

DropdownActions.propTypes = {
  close: PropTypes.func,
  save: PropTypes.func,
  disabled: PropTypes.bool
}

export default DropdownActions
